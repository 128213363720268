import { useEffect, useState, useRef } from "react";
// import { baseUrl } from "api/constants";
// import dynamic from "next/dynamic";
//import { Col } from "react-bootstrap";
//import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
//import { saveShortList } from "redux/actions/shortsAction";
import { getLanguage } from "localization/i8intl";

// const NewsTopbar = dynamic(() => import("components/newsCard/newsTopbar"));
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
const AuthorColumn = ({ authorWidgetData, size }) => {
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);
  const [authorData, setAuthorData] = useState(authorWidgetData);
  const len = authorWidgetData?.length;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  // const [shortData, setShortData] = useState(shortsData);
  const dispatch = useDispatch();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (authorData?.length > 0 && size?.width > 499) {
      if (authorData?.length % 2 === 0) {
        setAuthorData((prevArray) => [...prevArray, null, null]);
      }
      if (authorData?.length % 2 !== 0) {
        setAuthorData((prevArray) => [...prevArray, null, null, null]);
      }
    }
  }, []);
  console.log("authorWidgetData fro", authorWidgetData);
  const AuthorColumn = ({ index }) => {
    console.log("authorWisdsdfdgetData", index);

    return (
      <>
        {index <= authorData?.length - 3 ? (
          <div className="authorColumn-outer">
            <a
              href={`/authors/${authorData[index]?.email.split("@")[0]}?${
                authorData[index]?._id
              }`}
              target="_blank"
              style={{ cursor: "pointer" }}
              rel="noreferrer"
            >
              <div className="author-outer">
                <img
                  src={`${authorData[index]?.profileImage}`}
                  loading="lazy"
                />
                <span>{`${authorData[index]?.fullname}`} </span>
              </div>
            </a>

            {index + 2 <= len && (
              <a
                href={`/authors/${authorData[index + 1]?.email.split("@")[0]}?${
                  authorData[index + 1]?._id
                }`}
                target="_blank"
                style={{ cursor: "pointer" }}
                rel="noreferrer"
              >
                <div className="author-outer">
                  <img
                    src={`${authorData[index + 1]?.profileImage}`}
                    loading="lazy"
                  />
                  <span>{`${authorData[index + 1]?.fullname}`} </span>
                </div>
              </a>
            )}
          </div>
        ) : (
          <a href="/authors">
            {" "}
            <div
              className="see-more-author"
              style={{ marginBottom: "85px", marginLeft: "32px" }}
            >
              {" "}
              <img src="https://static.thelallantop.com/images/post/1720502683214_vector_(7).webp" />
              <span>View All</span>
            </div>
          </a>
        )}
      </>
    );
  };

  return (
    <>
      {authorData?.length > 0 && (
        <div
          className="outer-main-conainer outer-shadow"
          style={{
            marginTop: "0.8rem",
            paddingTop: "0.7rem",
            marginBottom: "1.5rem",
          }}
        >
          <div
            className="main-containerTopShow"
            style={{ flexDirection: "column !important" }}
          >
            <div className="authorTopBar">
              <a href="/authors" target="_blank">
                {" "}
                <div className="left-side">
                  <img
                    src={
                      "https://static.thelallantop.com/images/post/1720437460055_vector_(5).webp"
                    }
                  />
                  <span>
                    {language === "hi"
                      ? "आपके पसंदीदा एंकर"
                      : "Your Favorite Anchor"}
                  </span>
                </div>
              </a>
              {size.width > 499 && (
                <div className="right-side ">
                  {
                    <div
                      className="author-slider-button-bwd"
                      style={
                        !isBeginning
                          ? { display: "block" }
                          : { backgroundColor: "#80808024" }
                      }
                      ref={prevRef}
                    >
                      <img src="https://static.thelallantop.com/images/post/1720502393026_vector_(6).webp" />
                    </div>
                  }
                  {
                    <div
                      className="author-slider-button-fwd"
                      style={
                        !isEnd
                          ? { display: "block" }
                          : { backgroundColor: "#80808024" }
                      }
                      ref={nextRef}
                    >
                      <img src="https://static.thelallantop.com/images/post/1720502683214_vector_(7).webp" />
                    </div>
                  }
                </div>
              )}
            </div>

            <div className="checkDesktopOrNot" style={{ marginTop: "2.6rem" }}>
              <>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={10}
                  slidesPerGroup={3}
                  freeMode={true}
                  // navigation={true}
                  pagination={{
                    clickable: true,
                  }}
                  onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    console.log(prevRef.current);
                    console.log(nextRef.current);
                    swiper.on("slideChange", () => {
                      setIsBeginning(swiper.isBeginning);
                      setIsEnd(swiper.isEnd);
                    });
                    swiper.navigation.init();
                    swiper.navigation.update();
                  }}
                  modules={[FreeMode, Navigation]}
                  className="mySwiper shorts-slider"
                >
                  {authorData?.map((data, index) => (
                    <div key={index}>
                      {index % 2 === 0 && (
                        <SwiperSlide
                          key={index}
                          index={index}
                          style={
                            index + 1 === authorData?.length - 1
                              ? { height: "auto" }
                              : {}
                          }
                        >
                          <div
                            index={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "auto 0",
                            }}
                          >
                            <AuthorColumn index={index} />
                          </div>
                        </SwiperSlide>
                      )}
                    </div>
                  ))}
                  {/* <button onClick={() => swiper?.slidePrev()}>previous</button> */}
                </Swiper>
              </>
            </div>
            <div className="checkMobileOrNot">
              <div className="scrollbarX author-container">
                {authorData.map((item, index) => {
                  return (
                    <>
                      {index + 1 !== authorWidgetData?.length ? (
                        <a
                          href={`/authors/${
                            authorData[index]?.email.split("@")[0]
                          }?${authorData[index]?._id}`}
                          target="_blank"
                          style={{ cursor: "pointer" }}
                          rel="noreferrer"
                        >
                          <div className="author-image-container">
                            <img
                              src={`${authorData[index]?.profileImage}`}
                              alt="favorite-anchors-image"
                              height="100px"
                              width="100px"
                            />
                            <div className="author-name">
                              <div className="author-first-name">
                                {`${authorData[index]?.fullname}`}{" "}
                              </div>
                            </div>
                          </div>
                        </a>
                      ) : (
                        <a
                          href="/authors"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {" "}
                          <div
                            className="see-more-author"
                            style={{ width: "70px" }}
                          >
                            {" "}
                            <img src="https://static.thelallantop.com/images/post/1720502683214_vector_(7).webp" />
                            <span>View All</span>
                          </div>
                        </a>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AuthorColumn;
